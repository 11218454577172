import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {Button, Typography} from '@mui/material';
import BtnGrandQuizz from '../../assets/img/btn-grandQuizz.png';
import BtnGrandArt from '../../assets/img/btn-grandArt.png';
import BtnSnakyFoot from '../../assets/img/btn-foot.svg';
//import BtnSnakyChance from '../../assets/img/btn-roulette.png';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//import { checkIfClientAlreadyPlayed } from '../../services/ClientsService';
//import { accountService } from '../../_services/account_service';

export default function GameChoice() {

    /* ACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
    //const [displayBtn, setDisplayBtn] = useState( false );

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'white'
    };

    const navigate = useNavigate();
    const { t } = useTranslation();

    /* ACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
    //const email = accountService.getEmail();

    /* ACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */
    //useEffect( () => {
    //    (async () => {
    //        let result = await checkIfClientAlreadyPlayed( email );
    //
    //        if ( !result.error ) {
    //            if ( result.data === true ) {
    //                setDisplayBtn( true );
    //            }
    //        }
    //    })();
    //} );

    return (
        <div style={containerStyle}>
            <Grid container justifyContent="center" alignItems="center" spacing={2} sx={{ margin: '10px' }}
                  flexDirection="column">
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Typography variant="h1"
                                color="rgba(25, 60, 141, 1)"
                                textAlign="left"
                                letterSpacing="0em"
                                sx={{
                                    fontSize: {
                                        xs: '40px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                        sm: '42px', // Taille de police pour les écrans de taille 'sm' (small)
                                        md: '44px', // Taille de police pour les écrans de taille 'md' (medium)
                                        lg: '46px', // Taille de police pour les écrans de taille 'lg' (large)
                                        xl: '50px', // Taille de police pour les écrans de taille 'xl' (extra large)
                                    },
                                }}>
                        {t( 'game.label1' )}
                    </Typography>
                    <Typography
                        variant="h1"
                        color="rgba(25, 60, 141, 1)"
                        textAlign="left"
                        letterSpacing="0em"
                        sx={{
                            fontSize: {
                                xs: '40px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                sm: '42px', // Taille de police pour les écrans de taille 'sm' (small)
                                md: '44px', // Taille de police pour les écrans de taille 'md' (medium)
                                lg: '46px', // Taille de police pour les écrans de taille 'lg' (large)
                                xl: '50px', // Taille de police pour les écrans de taille 'xl' (extra large)
                            },
                        }}>
                        {t( 'game.label2' )}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} xl={3} style={{ marginBottom: '2px' }}>
                    <Typography
                        variant="subtitle1"
                        color="rgba(166, 166, 166, 1)"
                        textAlign="left"
                        fontWeight="700"
                        letterSpacing="0em"
                        sx={{
                            fontSize: {
                                xs: '14px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                sm: '16px', // Taille de police pour les écrans de taille 'sm' (small)
                                md: '16px', // Taille de police pour les écrans de taille 'md' (medium)
                                lg: '16px', // Taille de police pour les écrans de taille 'lg' (large)
                                xl: '16px', // Taille de police pour les écrans de taille 'xl' (extra large)
                            },
                        }}
                    >
                        {t( 'game.label3' )}
                    </Typography>
                </Grid>
                {/* PUBLICITÉ */}
                {/*
                <Grid item xs={12} sm={6} md={4} xl={3} style={{ marginBottom: '10px' }}>
                    <Typography
                        variant="h1"
                        textAlign="left"
                        letterSpacing="0em"
                        sx={{
                            fontSize: {
                                xs: '14px', // Taille de police pour les écrans de taille 'xs' (extra small)
                                sm: '16px', // Taille de police pour les écrans de taille 'sm' (small)
                                md: '18px', // Taille de police pour les écrans de taille 'md' (medium)
                                lg: '18px', // Taille de police pour les écrans de taille 'lg' (large)
                                xl: '18px', // Taille de police pour les écrans de taille 'xl' (extra large)
                            },
                        }}
                    >
                        <span style={{ color: '#193C8D' }}>
                            {t( 'game.label30' )}
                        </span>
                        <span style={{ color: '#FF6F61', fontWeight: 'bold' }}>
                            {t( 'game.label31' )}
                        </span>
                        <span style={{ color: '#193C8D' }}>
                            {t( 'game.label32' )}
                        </span>
                        <span style={{ color: '#00BFFF', fontWeight: 'bold' }}>
                            {t( 'game.label33' )}
                        </span>
                        <div style={{ fontSize: '0.7em', marginTop: '10px' }}>
                            <a href="https://snaky.be/concours-reglement/" target="_blank"
                               rel="noreferrer">{t( 'game.label34' )}</a>
                        </div>
                    </Typography>
                </Grid>
                */}
                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Button
                        onClick={() => navigate( '/game/grandquizz' )}
                    >
                        <img src={BtnGrandQuizz} alt="Button Grand Quizz" style={{ width: '100%', height: 'auto' }}/>
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Button
                        onClick={() => navigate( '/game/choiximage' )}
                    >
                        <img src={BtnGrandArt} alt="Button Grand Art" style={{ width: '100%', height: 'auto' }}/>
                    </Button>
                </Grid>

                <Grid item xs={12} sm={6} md={4} xl={3}>
                    <Button
                        onClick={() => navigate( '/game/snakyfoot' )}
                    >
                        <img src={BtnSnakyFoot} alt="Button Snaky foot" style={{ width: '100%', height: 'auto' }}/>
                    </Button>
                </Grid>

                {/* ACTIVER CECI QUAND ON VEUT REMETTRE LA ROULETTE */}


                {/* {!displayBtn ?*/}
                {/*    <Grid item xs={12} sm={6} md={4} xl={3}>*/}
                {/*        <Button*/}
                {/*            onClick={() => navigate( '/game/roulette' )}*/}
                {/*        >*/}
                {/*            <img src={BtnSnakyChance} alt="Button Snaky Chance"*/}
                {/*                 style={{ width: '100%', height: 'auto' }}/>*/}
                {/*        </Button>*/}
                {/*    </Grid>*/}
                {/*    : null*/}
                {/*}*/}

            </Grid>
        </div>
    );

}
